import { render, staticRenderFns } from "./high-busbar.vue?vue&type=template&id=2c95fae7&scoped=true"
import script from "./high-busbar.vue?vue&type=script&lang=js"
export * from "./high-busbar.vue?vue&type=script&lang=js"
import style0 from "./high-busbar.vue?vue&type=style&index=0&id=2c95fae7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c95fae7",
  null
  
)

export default component.exports